import { Component } from "react";
import List from "./components/List";
import ToggleButton from "./components/ToggleButton";
import axios from "axios";

import "./style/App.css";
import HashTable from "./common/HashTable";
import Menu from "./components/Menu";
import Cookies from "./components/Cookies";

const TLDSource = "https://data.iana.org/TLD/tlds-alpha-by-domain.txt";
const junkMailDomains = require("./static/junkDomains.json");
const trustedMailDomains = require("./static/trustedDomains.json");

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: "",                     
            outputMode: "json",

            domainMode: "whitelist",
            domainList: ["gmail", "yahoo"],  

            filterTLDMode: "blacklist",
            filterTLDList: [],

            validateTLDEnabled: true,
            validTLDs: [],

            knownDomains: {
                "gmail": "com",
                "msn": "com",
                "aol": "com",                
                "protonmail": "com",
                "seznam": "cz"
            },

            validMails: "",   
            result: null,
            lastRunRemoved: 0,
            lastRunTime: 0,

            cookiesAllowed: null,
            cookiesVisible: false,
            cookiesStoreFields: ["domainMode", "domainList", "filterTLDMode", "filterTLDList"]

        }

        this.addItemToDomainList = this.addItemToDomainList.bind(this);
        this.removeItemFromDomainList = this.removeItemFromDomainList.bind(this);
        this.removeAllItemsFromDomainList = this.removeAllItemsFromDomainList.bind(this);


        this.addItemToTLDList = this.addItemToTLDList.bind(this);
        this.removeItemFromTLDList = this.removeItemFromTLDList.bind(this);
        this.removeAllItemsFromTLDList = this.removeAllItemsFromTLDList.bind(this);

        this.allowCookies = this.allowCookies.bind(this);
        this.rejectCookies = this.rejectCookies.bind(this);
    }

    componentDidMount() {    
        let cookiesAllowed = localStorage.getItem("allowed");

        if (cookiesAllowed === null) {
            this.setState(state => ({
                cookiesVisible: true
            }));
        } else {
            this.setState(state => ({
                cookiesAllowed: cookiesAllowed === "true" ? true : false
            }));        
        }

        this.loadFromCookies();
        this.loadValidTLDs();
    }

    loadFromCookies() {
        for (let i = 0; i < this.state.cookiesStoreFields.length; i++) {
            let item = localStorage.getItem(this.state.cookiesStoreFields[i]);
            if (item !== null) {
      
                this.setState(state => ({
                    [this.state.cookiesStoreFields[i]]: JSON.parse(item)
                }))
            }
        }
    }

    allowCookies() {
        this.setState(state => ({
            cookiesAllowed: true,
            cookiesVisible: false
        }), localStorage.setItem("allowed", "true"))
    }

    rejectCookies() {
        this.setState(state => ({
            cookiesAllowed: false,
            cookiesVisible: false
        }), localStorage.setItem("allowed", "false"))
    }

    loadValidTLDs() {
        axios.get(TLDSource).then((response) => {
            let rows = response.data.toLocaleLowerCase().split("\n");
            rows.splice(0, 1);
            this.setState(state => ({validTLDs: rows}));
        }).catch(function (error) {
            console.error(error);
            return;
        })
    }

    saveCurrentSettingsToLocalStorage() {
        for (let i = 0; i < this.state.cookiesStoreFields.length; i++) {
            localStorage.setItem(this.state.cookiesStoreFields[i], JSON.stringify(this.state[this.state.cookiesStoreFields[i]]));
        }
    }

    checkMails() {        
        const startTime = + new Date();

        const inputRows = (this.state.input.toLocaleLowerCase()).split("\n");
        let result = new HashTable(Math.round(inputRows.length / 2));

        if (this.state.cookiesAllowed) {
            this.saveCurrentSettingsToLocalStorage();
        }

        for (let i = 0; i < inputRows.length; i++) {
            if (this.checkMail(inputRows[i])) {
                if (!this.validateDomain(inputRows[i])) {
                    continue
                }

                if (!this.validateTLDList(inputRows[i])) {
                    continue;
                }

                if (this.state.validateTLDEnabled && !this.validateTLD(inputRows[i])) {        
                    continue;
                }

                if (!this.validateKnownDomains(inputRows[i])) {
                    continue;
                }

                result.set(inputRows[i], true);                
            }            
        }

        const resultKeys = result.getAllKeys();     
        let resultString = ""  

        if (this.state.outputMode == "json") {
            resultString = JSON.stringify(resultKeys);            
        } else {
            for (let i = 0; i < resultKeys.length; i++) {
                resultString += resultKeys[i] + "\n";
            }
        }       
        
        this.setState(({
            validMails: resultString,
            result: result,
            lastRunRemoved: inputRows.length - resultKeys.length,
            lastRunTime: ((+ new Date() - startTime)/1000).toFixed(1)
        }))
    }

    checkMail(email) {
        return this.validateFormat(email);
    }

    validateKnownDomains(email) {
        const split = (email.split("@")[1]).split(".");

        const domain = split[0];        
        const tld = split[split.length-1];
        
        for (const key in this.state.knownDomains) {
            if (key === domain && tld !== this.state.knownDomains[key]) {
                return false;
            }
        }
        return true;
    }

    validateTLD(email) {        
        const split = (email.split("@")[1]).split(".");
        let term = split[split.length-1];

        let start = 0;
        let stop = this.state.validTLDs.length
        let middle = Math.floor(this.state.validTLDs.length / 2);

        while(this.state.validTLDs[middle] !== term && start < stop) {
            if (term < this.state.validTLDs[middle]) {
                stop = middle - 1;
            } else if (term > this.state.validTLDs[middle]) {
                start = middle + 1;
            }

            middle = Math.floor((stop + start)/2);
        }

        return this.state.validTLDs[middle] === term;
    }
    
    validateTLDList(email) {
        const tlds = (email.split("@")[1]).split(".");
        const tld = "." + tlds[tlds.length-1];

        if (this.state.filterTLDMode === "blacklist") {
            return this.state.filterTLDList.indexOf(tld) === -1
        }
        
        return (this.state.filterTLDList.indexOf(tld) !== -1 || this.state.filterTLDList.length === 0);
    }   

    validateDomain(email) {
        const domain = (email.split("@")[1]).split(".")[0];
        if (this.state.domainMode === "blacklist") {
            return (this.state.domainList.indexOf(domain) === -1);
        }

        return (this.state.domainList.indexOf(domain) !== -1 || this.state.domainList.length === 0);      
    }

    validateFormat(email) {
        const format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(format)) {
            return true;
        }

        return false;
    }

    setMode(mode) {
        this.setState(({
            domainMode: mode
        }), () => {this.checkMails()})
    }

    setOutputMode(mode) {
        if (mode === this.state.outputMode) {
            return;
        }

        let resultString = "" 
        if (this.state.result !== null) {
            const resultKeys = this.state.result.getAllKeys();

            if (mode == "json") {
                resultString = JSON.stringify(resultKeys);            
            } else {
                for (let i = 0; i < resultKeys.length; i++) {
                    resultString += resultKeys[i] + "\n";
                }
            }
        }

        this.setState(({
            outputMode: mode,
            validMails: resultString
        }))
    }

    setInput(e) {
        this.setState(state => ({
            input: e.target.value
        }), () => {this.checkMails()})
    }

    addItemToDomainList(item, callback) {
        if (item == "" || this.state.domainList.indexOf(item) !== -1) {
            return;
        }

        this.setState(state => ({
            domainList: [...state.domainList, item] 
        }), () => {
            callback();
            this.checkMails();
        })
    }

    removeItemFromDomainList(item) {        
        let newResults = [...this.state.domainList];
        newResults.splice(newResults.indexOf(item), 1);  
        
        this.setState(state => ({
            domainList: newResults
        }), () => {this.checkMails()})
    }

    removeAllItemsFromDomainList() {
        this.setState(state => ({
            domainList: []
        }), () => {this.checkMails()})
    }

    addItemToTLDList(item, callback) {
        item = item.toLocaleLowerCase().replace(/[^a-z]/gi, '')
        item = "." + item;

        if (item == "" || this.state.filterTLDList.indexOf(item) !== -1) {
            return;
        }        

        this.setState(state => ({
            filterTLDList: [...state.filterTLDList, item] 
        }), () => {
            callback();
            this.checkMails();
        })
    }

    removeItemFromTLDList(item) {        
        let newResults = [...this.state.filterTLDList];
        newResults.splice(newResults.indexOf(item), 1);  
        
        this.setState(state => ({
            filterTLDList: newResults
        }), () => {this.checkMails()})
    }

    removeAllItemsFromTLDList() {
        this.setState(state => ({
            filterTLDList: []
        }), () => {this.checkMails()})
    }

    populateDomains(domains) {
        this.setState(state => ({
            domainList: [...new Set([...state.domainList, ...domains])]
        }), () => {
            this.checkMails();
        })
    }

    renderDomainListTip() {
        if (this.state.domainMode === "whitelist") {
            return <div className="tooltip">
                You have the whitelist mode selected, this will filter only mails with domain on the current domains list. E.g. setting "gmail" and "yahoo" will result in the output containing only emails with domain "gmail" or "yahoo".
            </div>
        }

        return <div className="tooltip">You have the blacklist mode selected, this will remove mails with domain the current domains list. E.g. setting "gmail" and "yahoo" will result in the output containing all emails, except for mails with domain "gmail" or "yahoo".</div>
    }

    renderTLDListTip() {
        if (this.state.filterTLDMode === "whitelist") {
            return <div className="tooltip">
                You have the whitelist mode selected, this will filter only mails with top level domain on current tlds list. E.g. setting ".com" and ".org" will result in the output containing only emails with tld ".com" or ".org".
            </div>
        }

        return <div className="tooltip">You have the blacklist mode selected, this will remove mails with top level domain the current tlds list. E.g. setting ".com" and ".org" will result in the output containing all emails, except for mails with tld ".com" or ".org".</div>
    }
    
    render() {
        return (
            <div className="contentWrapper">
                <div className="col leftCol">
                    <div className="logo">
                        <img src={require("./style/icons/logo.png")} alt="logo"/>       
                    </div>
                                 
                    
                    <Menu />                    
                </div>
                
                <div className="col rightCol">
                    <div className="logo mobileLogo">
                        <img src={require("./style/icons/logo.png")} alt="logo"/>       
                    </div>
                    <h1>Free Bulk Mail Validator</h1>
                    <div className="subtitle">Clean your mailing lists for free and avoid being placed on the blacklists.</div>

                    <div className="modeSwitch" id="settings">

                        <div className="optionWrapper">
                            <h2>Domains</h2>
                            <div>
                                <ToggleButton onClickFunction={() => {
                                    this.setMode("whitelist")
                                }} text={"Whitelist"} active={this.state.domainMode == "whitelist"}/>

                                <ToggleButton onClickFunction={() => {
                                    this.setMode("blacklist")
                                }} text={"Blacklist"} active={this.state.domainMode == "blacklist"}/>
                            </div>

                            { this.renderDomainListTip() }
                            
                            <h3>Current domains</h3>
                            <List 
                                items={this.state.domainList} 
                                addItem={this.addItemToDomainList}
                                removeItem={this.removeItemFromDomainList}
                                removeAllItems={this.removeAllItemsFromDomainList}
                            />

                            <h3>Presets</h3>
                            <div className="button buttonSmall" onClick={() => {
                                this.populateDomains(junkMailDomains);
                                this.setState(state => ({
                                    domainMode: "blacklist"
                                }));
                            }}><div>Junk domains</div></div>
                            <div className="button buttonSmall" onClick={() => {
                                this.populateDomains(trustedMailDomains);
                                this.setState(state => ({
                                    domainMode: "whitelist"
                                }));
                            }}><div>Popular domains</div></div>  
                        </div>
                        
                        <div className="optionWrapper">
                            <h2>TLDs</h2>
                            <div>
                                <ToggleButton onClickFunction={() => {
                                    this.setState(state => ({filterTLDMode: "whitelist"}))
                                }} text={"Whitelist"} active={this.state.filterTLDMode == "whitelist"}/>

                                <ToggleButton onClickFunction={() => {
                                    this.setState(state => ({filterTLDMode: "blacklist"}))
                                }} text={"Blacklist"} active={this.state.filterTLDMode == "blacklist"}/>
                            </div>

                            { this.renderTLDListTip() }
                            
                            <h3>Current TLDs</h3>
                            <List 
                                items={this.state.filterTLDList} 
                                addItem={this.addItemToTLDList}
                                removeItem={this.removeItemFromTLDList}
                                removeAllItems={this.removeAllItemsFromDomainList}
                            />
                        </div>

                        <div className="optionWrapper">
                            <h2>Additional options</h2> 

                            <ToggleButton onClickFunction={() => {
                                this.setState(state => ({
                                    validateTLDEnabled: !state.validateTLDEnabled
                                }), () => {this.checkMails()})
                            }} text={"Validate TLD"} active={this.state.validateTLDEnabled}/>                              
                        </div>         
                    </div>

                    <div className="inputWrapper" id="input">
                        <h2>Input</h2>
                        <textarea placeholder="Add one item per row" onChange={e => this.setInput(e)}></textarea>
                    </div>

                    <div className="optionWrapper" id="output">
                        <h2>Output</h2>
                        <h3>Output mode</h3>
                        <div>
                            <ToggleButton onClickFunction={() => {
                                this.setOutputMode("plaintext")
                            }} text={"Plaintext"} active={this.state.outputMode == "plaintext"}/>
                            <ToggleButton onClickFunction={() => {
                                this.setOutputMode("json")
                            }} text={"JSON"} active={this.state.outputMode == "json"}/>
                        </div>
                        <textarea value={this.state.validMails} readOnly={true}></textarea>        
                        { this.state.input !== "" ? "Removed " + this.state.lastRunRemoved + " invalid entries in " + this.state.lastRunTime + "s" : "" }  

                    </div>
                              
                </div> 

                <div className="col adCol">

                </div>

                {this.state.cookiesVisible ? <Cookies allowCookies={this.allowCookies} rejectCookies={this.rejectCookies}/> : ""}
                       
            </div>)
    }
}

export default App;
