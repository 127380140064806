import { Component } from "react";

class ToggleButton extends Component {
    render() {
        return <div className={"button " + (this.props.active ? "buttonActive" : "")} onClick={() => this.props.onClickFunction()}>
            <div>{this.props.text}</div>
        </div>
    }
}

export default ToggleButton;