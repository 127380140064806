import { Component } from "react";

class MenuItem extends Component {
    render() {
        return(
            <div onClick={() => this.props.changeActiveMenuItem(this.props.name)}>
                <a href={this.props.link}>
                    <div className={(this.props.active ? "menuItemActive" : "")+ " menuItem"}>                        
                        <div className="activeIcon">
                            {this.props.active ? <img src={require("../style/icons/active.png")} alt="" /> : ""}
                            
                        </div>
                        <div className="menuIcon">
                            <img src={this.props.active ? this.props.activeIcon : this.props.icon} alt="" />
                        </div>                            
                        <div className="menuText"> {this.props.name} </div>
                    </div>
                </a>
            </div>
        )
    }
}

class Menu extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activeMenuItem: "settings",
            menuItems: [
                {name: "settings", icon: require("../style/icons/settings.png"), activeIcon: require("../style/icons/settings_active.png"), link: "#settings"},
                {name: "input", icon: require("../style/icons/input.png"), activeIcon: require("../style/icons/input_active.png"), link: "#input"},
                {name: "output", icon: require("../style/icons/output.png"), activeIcon: require("../style/icons/output_active.png"), link: "#output"},
                {name: "donate", icon: require("../style/icons/donate.png"), activeIcon: require("../style/icons/donate_active.png"), link: "#donate"},
                {name: "about", icon: require("../style/icons/about.png"), activeIcon: require("../style/icons/about_active.png"), link: "#about"},
            ]
        }

        this.changeActiveMenuItem = this.changeActiveMenuItem.bind(this);
    }

    changeActiveMenuItem(name) {
        this.setState(state => ({
            activeMenuItem: name
        }))
    }

    render() {
        let result = [];

        for (let i = 0; i < this.state.menuItems.length; i++) {
            result.push(<MenuItem 
                active={this.state.activeMenuItem === this.state.menuItems[i].name}                
                name={this.state.menuItems[i].name}
                icon={this.state.menuItems[i].icon}
                activeIcon={this.state.menuItems[i].activeIcon}
                link={this.state.menuItems[i].link}
                changeActiveMenuItem={this.changeActiveMenuItem}
            />)
        }

        return result;
    }
}

export default Menu;