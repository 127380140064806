class HashTable {
    constructor(size) {
        this.table = new Array(size);
        this.size = 0;
    }

    hash(key) {
        //Hashing by summing up ASCII values of chars
        let hash = 0;

        for (let i = 0; i < key.length; i++) {
            hash += key.charCodeAt(i);
        }

        return hash % this.table.length;
    }

    set(key, value) {
        const hash = this.hash(key);
        if (this.table[hash]) {
            for (let i = 0; i < this.table[hash].length; i++) {
                if (this.table[hash][i][0] === key) {
                    this.table[hash][i][1] = value;
                    return;
                }
            }

            // if not found push a new key/value pair
            this.table[hash].push([key, value]);
        } else {
            this.table[hash] = [];
            this.table[hash].push([key, value]);
        }

        this.size++;
    }

    get(key) {
        const hash = this.hash(key);
        if (this.table[hash]) {
            for (let i = 0; i < this.table.length; i++) {
                if (typeof this.table[hash][i] !== "undefined" && this.table[hash][i][0] === key) {
                    return this.table[hash][i][1];
                }
            }
        }
        return undefined;
    }

    getAllKeys() {
        let keys = [];

        for (let i = 0; i < this.table.length; i++) {
            if (typeof this.table[i] === "undefined") {
                continue;
            }

            for (let j = 0; j < this.table[i].length; j++) {
                keys.push(this.table[i][j][0]);
            }
        }

        return keys;
    }

    remove(key) {
        const hash = this._hash(key);

        if (this.table[hash] && this.table[hash].length) {
            for (let i = 0; i < this.table.length; i++) {
                if (this.table[hash][i][0] === key) {
                    this.table[hash].splice(i, 1);
                    this.size--;
                    return true;
                }
            }
        } else {
            return false;
        }
    }
}

export default HashTable;