import { Component } from "react";

class Cookies extends Component {
    render() {
        return (
            <div className="cookieAlert">
                <div className="cookieIcon"><img src={require("../style/icons/info.png")} /></div>
                <div className="cookieHeadline">We do not save your mailing lists!</div>
                <div className="cookieSubheadline">Select Your Cookie Preferences</div>

                <div className="cookieContent">
                    <div className="cookieText">We use cookies and similar tools that are necessary to enable you to make purchases, to enhance your shopping experiences and to provide our services, as detailed in our Cookie Notice. We also use these cookies to understand how customers use our services (for example, by measuring site visits) so we can make improvements.</div>
                    <div className="cookieButtons">
                        <div className="button buttonActive"><div onClick={() => this.props.allowCookies()}>Accept cookies</div></div>
                        <div className="button"><div onClick={() => this.props.rejectCookies()}>Reject cookies</div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cookies;