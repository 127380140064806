import { Component } from "react";

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: ""
        }

        this.addItem = this.addItem.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
    }
    
    addItem() {
        this.props.addItem(this.state.input, () => {
            this.setState(({
                input: ""
            }))
        });        
    }
    
    onKeyUp(event) {
        if (event.charCode === 13) {   
            this.setState(({ input: event.target.value }), () => {
                event.target.value = "";
                this.addItem()
            });             
        }    
    }
    

    removeItem(id) {
        this.props.removeItem(id)
    }

    removeAllItems() {
        this.props.removeAllItems();
    }

    renderCurrentItems() {
        let returnValue = [];

        this.props.items.forEach(element => {
            returnValue.push(<div className="listItem" key={element}>                 
                <div>{element}</div>
                <img src={require("../style/icons/close.png")} alt="close" onClick={() => this.removeItem(element)} />
            </div>)
        });

        return returnValue;
    }

    renderClearAllButton() {
        if (this.props.items.length > 0) {
            return (
                <div onClick={this.removeAllItems} className="button buttonDanger">
                    <div>Clear all</div>
                </div>
            )
        }

        return ""
    }

    render() {
        return <div className="listComponent">
            <div className="currentItems">
                { this.renderCurrentItems() }
                <div className="addItemWrapper">
                    <div>
                        <input onKeyPress={ this.onKeyUp } placeholder="+ Add item"></input>
                    </div>                
                </div>
                { this.renderClearAllButton() }                
            </div>
            
            
        </div>
    }
}

export default List;